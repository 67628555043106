import { formatDistance } from 'date-fns'
import { format as formatDate, utcToZonedTime } from 'date-fns-tz'
import {
  ACCESS_TOKEN_KEY,
  CURRENT_TENANT_ID,
  CURRENT_USER_ID,
  ONBOARDING_STORAGE_KEY,
  TEMP_ACCESS_TOKEN_KEY,
} from './constants'
import { passwordErrorCodeDictionary } from './strings'

export function removeKey(key) {
  localStorage.removeItem(key)
}

export function storeKey(key, value) {
  if (typeof value === 'undefined') {
    removeKey(key)
    return
  }

  localStorage.setItem(key, btoa(value))
}

export function fetchKey(key) {
  if (typeof localStorage === 'undefined') return

  const value = localStorage.getItem(key)
  if (!value) return value

  return atob(value)
}

export function removeAuthKeys() {
  removeKey(ACCESS_TOKEN_KEY)
  removeKey(TEMP_ACCESS_TOKEN_KEY)
  removeKey(ONBOARDING_STORAGE_KEY)
  removeKey(CURRENT_USER_ID)
}

export function removeTenantKey() {
  removeKey(CURRENT_TENANT_ID)
}

export function validatePassword(password) {
  return RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})').test(password)
}

export function validateEmail(email) {
  return RegExp('^[^@]+@[^@]+(\\.[^.@]+)+$').test(email)
}

export function requiresWorkEmailValidation(referralCode) {
  if (referralCode?.requiredEmailVerificationDomains) {
    return true
  }

  return false
}

export function mapPasswordErrorCodesToString(errorCodes) {
  if (!errorCodes) return []

  return errorCodes.map((code) => {
    return passwordErrorCodeDictionary[code]
  })
}

export function mapErrorCodesToString(errorCodes, dictionary) {
  return Object.keys(errorCodes).reduce((memo, field) => {
    memo[field] = errorCodes[field].map((code) => {
      const str =
        (dictionary[field] && dictionary[field][code]) || dictionary[code]
      return str || code
    })
    return memo
  }, {})
}

export function localTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function utcToLocalTime(date) {
  return utcToZonedTime(date, localTimeZone())
}

export function formatTime(date, format) {
  if (!date) return date

  return formatDate(date, format, { timeZone: localTimeZone() })
}

export function formatTimeDistance(from, to = new Date()) {
  if (!from) return from

  return formatDistance(from, to)
}

export function authorizationHeaders() {
  const headers = { 'X-Portal-App': 'account' }
  const token = fetchKey(ACCESS_TOKEN_KEY)

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}

export async function retry(fn, retries = 3, delay = 1, err = null) {
  if (!retries) {
    return Promise.reject(err)
  }

  await new Promise((resolve) => setTimeout(resolve, delay * 1000))

  try {
    return fn().catch((err) => {
      return retry(fn, retries - 1, delay + 1, err)
    })
  } catch (err) {
    return retry(fn, retries - 1, delay + 1, err)
  }
}
